import {
  CheckCircleIcon,
  CreditCardIcon,
  ExclamationIcon,
  PaperAirplaneIcon,
  PlusSmIcon,
  UploadIcon,
  XIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import { useContext, useRef, useState } from "react";
import {
  displayDate,
  displayFileSize,
  verifyCard,
  verifyFlightNo,
} from "../action";
import {
  submitOnlineCheckIn,
  uploadReservationFiles,
} from "../action/actionGuest";
import useSubmitWithToast from "../action/useSubmitWithToast";
import { Button, Input } from "../components";
import { PropertyContext } from "../context/property";
import {
  getCCExpiryYear,
  getCurrentMonth,
  getCurrentYear,
  nextTenYearsArr,
} from "../helpers";

const arrivalTimeOptions = [
  "2:00PM - 3:00PM",
  "3:00PM - 4:00PM",
  "4:00PM - 5:00PM",
  "5:00PM - 6:00PM",
  "6:00PM - 7:00PM",
  "7:00PM - 8:00PM",
  "8:00PM - 9:00PM",
  "9:00PM - 10:00PM",
  "10:00PM - 11:00PM",
  "11:00PM - Midnight",
  "Later than midnight",
];

const hours = Array(24)
  .fill("d")
  .map((n, index) => String(index).padStart(2, "0"));

export default function OnlineCheckIn({
  reservationId,
  reservation,
}: {
  propertyId: string;
  reservationId: string;
  reservation: any;
}) {
  const { property } = useContext(PropertyContext);

  const { allowOnlineCheckInPeriod } = property?.guestPortal || {};

  const { checkIn, isCheckedInOnline } = reservation || {};

  const today = dayjs().format("YYYY-MM-DD");
  const onlineCheckInAllowDate = dayjs(checkIn)
    .subtract(+allowOnlineCheckInPeriod, "days")
    .format("YYYY-MM-DD");
  const isOnlineCheckInAllowed =
    +allowOnlineCheckInPeriod > 0 ? onlineCheckInAllowDate <= today : true;

  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            Online Check In
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-8 sm:px-0">
            {!isCheckedInOnline ? (
              <CheckInForm
                reservation={reservation}
                reservationId={reservationId}
              />
            ) : !isOnlineCheckInAllowed ? (
              <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center">
                <span className="mt-2 block text-sm font-medium text-gray-900">
                  {`Online Check In is not open yet. Please come back on or after ${displayDate(
                    onlineCheckInAllowDate
                  )}.`}
                </span>
              </div>
            ) : (
              <div className="relative block w-full border-2 border-green-300 border-dashed rounded-lg p-12 text-center bg-green-50">
                <CheckCircleIcon className="h-24 w-24 mx-auto text-green-500" />
                <span className="mt-4 block text-sm font-medium text-green-800">
                  The online check in is completed.
                </span>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
}

const CheckInForm = ({
  reservation: prevRes,
  reservationId,
}: {
  reservation: any;
  reservationId: string;
}) => {
  const [profile, setProfile]: [any, any] = useState({});
  const [idFiles, setIdFiles]: [any, any] = useState();
  const [vaxFiles]: [any, any] = useState();
  const [errors, setErrors]: [any, any] = useState({});
  const formTop: any = useRef(null);

  const { property, propertyId, handleReservation } =
    useContext(PropertyContext);

  const {
    tandc,
    isIDRequired,
    alertMessage,
    isCreditCardDetails,
    creditCardMessage,
    isFlightDetails,
  } = property?.guestPortal || {};

  const { mobile: mobilePrev, email: emailPrev } = prevRes || {};

  const isEmailRelay =
    /@agoda-messaging\.com|@guest\.booking\.com|@guest\.airbnb\.com|@m\.expediapartnercentral\.com/.test(
      emailPrev
    );

  const isMobileRequired = !mobilePrev;
  const isEmailRequired = !emailPrev || isEmailRelay;

  const {
    mobile,
    email,
    carRego,
    arrivalTime,
    agreeToTC,
    guestRequest,
    additionalGuests,
    cardName,
    cardNumberRaw,
    expiryMonth,
    expiryYear,
    cvcNumberRaw,
    arrivalFlight,
    arrivalFlightTimeHour,
    arrivalFlightTimeMinute,
    arrivalDetail,
    departureFlight,
    departureFlightTimeHour,
    departureFlightTimeMinute,
    departureDetail,
  } = profile || {};

  const handleInput = (e: any) =>
    setProfile({
      ...profile,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });

  const [handleSubmit] = useSubmitWithToast({
    func: async () => {
      const errors2 = handleValidate();
      const isValid = !!(Object.keys(errors2)?.length === 0);

      if (!isValid) {
        window.scrollTo({
          top: formTop?.current?.offsetTop || 0,
          left: 0,
          behavior: "smooth",
        });
        setErrors(errors2);
        throw Error(`Please complete all the required fields.`);
      } else {
        try {
          const attachments = await uploadReservationFiles({
            idFiles,
            vaxFiles,
            propertyId,
          });

          const code = localStorage.getItem("code") || "";
          await submitOnlineCheckIn({
            propertyId,
            reservationId,
            profile,
            code,
            attachments,
          });
        } catch (err: any) {
          alert(err?.message);
          throw Error(err?.message);
        }
      }

      return Promise.resolve();
    },
    successMessage: "You are now successfully checked in online.",
    successFunc: () => {
      handleReservation();
    },
    loadingText: "Submitting your online check in records...",
  });

  const handleValidate = () => {
    const errors2: any = {};

    if (!arrivalTime) {
      errors2.arrivalTime = "Required";
    }

    if (isEmailRequired) {
      if (!email) {
        errors2.email = "Required";
      }

      const isValidEmail = /^\S+@\S+\.\S+$/.test(email);
      if (!isValidEmail) {
        errors2.email = "Incorrect email format";
      }
    }

    if (isMobileRequired) {
      if (!mobile) {
        errors2.mobile = "Required";
      }
    }

    const isIDFilled = !isIDRequired
      ? !idFiles?.length || +idFiles?.length < 1
      : false;
    if (isIDFilled) {
      errors2.idFiles = "Required";
    }

    if (isFlightDetails === "required") {
      if (!arrivalFlight) {
        errors2.arrivalFlight = true;
      }
    }

    if (!!arrivalFlight && !verifyFlightNo(arrivalFlight)) {
      errors2.arrivalFlight = true;
    }

    if (!!departureFlight && !verifyFlightNo(departureFlight)) {
      errors2.departureFlight = true;
    }

    if (!agreeToTC) {
      errors2.agreeToTC = "Required";
    }

    if (isCreditCardDetails === "required") {
      if (!cardName) {
        errors2.cardName = "Required";
      }

      if (!expiryMonth) {
        errors2.expiryMonth = "Required";
      }

      if (!expiryYear) {
        errors2.expiryYear = "Required";
      }
      if (Number(expiryYear) < Number(getCCExpiryYear(getCurrentYear())))
        errors2.expiryYear = "Expiry year cannot be before current year";

      const isExpiryBeforeCurrentMonth =
        Number(expiryYear) === Number(getCCExpiryYear(getCurrentYear())) &&
        Number(expiryMonth) < Number(getCurrentMonth());
      if (isExpiryBeforeCurrentMonth)
        errors2.expiryYear = "Expiry date cannot be before today";

      const isCcValid = verifyCard(cardNumberRaw, []);
      if (!isCcValid) {
        errors2.cardNumberRaw = true;
      }

      const isCvcValid = /^[0-9]{3,4}$/.test(String(cvcNumberRaw));
      if (!isCvcValid) {
        errors2.cvcNumberRaw = "CVC is not valid.";
      }
    }

    return errors2;
  };

  return (
    <>
      {alertMessage && (
        <div className="rounded-md bg-yellow-50 p-4 mb-4 whitespace-pre-line">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm text-yellow-700">
                <p>{alertMessage}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="bg-white shadow overflow-hidden sm:rounded-lg"
        ref={formTop}
      >
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Arrival Time
                <span className="text-red-500 ml-2">*</span>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Input
                  type="select"
                  name="arrivalTime"
                  value={arrivalTime}
                  onChange={handleInput}
                  errors={errors}
                >
                  <option value=""></option>
                  {arrivalTimeOptions.map((time) => (
                    <option value={time} key={time}>
                      {time}
                    </option>
                  ))}
                </Input>
              </dd>
            </div>

            {isMobileRequired && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Mobile<span className="text-red-500 ml-2">*</span>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Input
                    type="text"
                    value={mobile}
                    name="mobile"
                    onChange={handleInput}
                    errors={errors}
                    placeholder="Mobile"
                  />
                </dd>
              </div>
            )}

            {isEmailRequired && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Email address<span className="text-red-500 ml-2">*</span>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Input
                    type="email"
                    value={email}
                    name="email"
                    onChange={handleInput}
                    errors={errors}
                    placeholder="Email address"
                  />
                </dd>
              </div>
            )}

            {isIDRequired !== "disabled" && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Government ID
                  {!isIDRequired && (
                    <span className="text-red-500 ml-2">*</span>
                  )}
                  <div className="text-gray-500 text-xs mt-1 italic">
                    Driver License or Passport
                  </div>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="relative">
                    <label
                      htmlFor="id-files"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600"
                    >
                      {/* <span>Upload files</span> */}
                      <span className="inline-flex items-center py-2 px-6 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <UploadIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium text-gray-900">
                          Upload Files
                        </span>
                      </span>
                      <input
                        id="id-files"
                        name="id-files"
                        type="file"
                        className="sr-only"
                        onChange={(e) => {
                          setIdFiles(e.target.files);
                        }}
                        multiple
                        accept="image/*,.pdf"
                      />
                    </label>

                    {errors?.idFiles && (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id={`idFiles-error`}
                      >
                        {errors?.idFiles}
                      </p>
                    )}
                    <div className="bg-white shadow overflow-hidden sm:rounded-md mt-3">
                      <ul className="divide-y divide-gray-200">
                        {Array.from(idFiles || []).map((file: any) => {
                          const { name, size } = file;
                          return (
                            <li key={name} className="py-2 px-3">
                              <div className="flex justify-between">
                                <p className="text-xs font-medium text-gray-900">
                                  {name}
                                </p>
                                <p className="text-xs text-gray-500">
                                  {displayFileSize(size)}
                                </p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </dd>
              </div>
            )}

            {!!isFlightDetails && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Flight Details
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="col-span-12 md:col-span-3 gap-x-5 grid grid-cols-3 mb-5">
                    <div className="col-span-12 md:col-span-1 mt-5 md:mt-0">
                      <label
                        htmlFor="arrivalFlight"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Arrival Flight No.
                        {isFlightDetails === "required" && (
                          <span className="text-red-500 ml-2">*</span>
                        )}
                      </label>
                      <div className="mt-1 relative mb-5">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <PaperAirplaneIcon
                            className={`h-5 w-5 ${
                              !arrivalFlight
                                ? "text-gray-400"
                                : verifyFlightNo(arrivalFlight)
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                        <Input
                          type="text"
                          name="arrivalFlight"
                          id="arrivalFlight"
                          autoComplete="arrivalFlight"
                          value={arrivalFlight}
                          onChange={handleInput}
                          className="mt-1 pl-10 rounded-md shadow-sm"
                          errors={errors}
                        />
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-1 mt-5 md:mt-0">
                      <label className="block text-sm font-medium text-gray-500">
                        Arrival Flight Time
                      </label>
                      <div className="grid grid-cols-2">
                        <Input
                          type="select"
                          name="arrivalFlightTimeHour"
                          id="arrivalFlightTimeHour"
                          autoComplete="arrivalFlightTimeHour"
                          value={arrivalFlightTimeHour}
                          onChange={handleInput}
                          className="mt-1 rounded-l-md rounded-r-none inline-block"
                          errors={errors}
                        >
                          {hours.map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                              {hour === "12" ? "PM" : ""}
                            </option>
                          ))}
                        </Input>
                        <Input
                          type="select"
                          name="arrivalFlightTimeMinute"
                          id="arrivalFlightTimeMinute"
                          autoComplete="arrivalFlightTimeMinute"
                          value={arrivalFlightTimeMinute}
                          onChange={handleInput}
                          className="mt-1 rounded-r-md rounded-l-none inline-block"
                          errors={errors}
                        >
                          <option value="00">00</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </Input>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-1 mt-5 md:mt-0">
                      <label
                        htmlFor="arrivalDetail"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Pickup Details
                      </label>
                      <Input
                        type="text"
                        name="arrivalDetail"
                        id="arrivalDetail"
                        autoComplete="arrivalDetail"
                        value={arrivalDetail}
                        onChange={handleInput}
                        className="mt-1"
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className="col-span-12 md:col-span-3 gap-x-5 grid grid-cols-3">
                    <div className="col-span-12 md:col-span-1 mt-5 md:mt-0">
                      <label
                        htmlFor="departureFlight"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Departure Flight No.
                      </label>
                      <div className="mt-1 relative mb-5">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <PaperAirplaneIcon
                            className={`h-5 w-5 ${
                              !departureFlight
                                ? "text-gray-400"
                                : verifyFlightNo(departureFlight)
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                        <Input
                          type="text"
                          name="departureFlight"
                          id="departureFlight"
                          autoComplete="departureFlight"
                          value={departureFlight}
                          onChange={handleInput}
                          className="mt-1 pl-10 rounded-md shadow-sm"
                          errors={errors}
                        />
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-1 mt-5 md:mt-0">
                      <label className="block text-sm font-medium text-gray-500">
                        Departure Flight Time
                      </label>
                      <div className="grid grid-cols-2">
                        <Input
                          type="select"
                          name="departureFlightTimeHour"
                          id="departureFlightTimeHour"
                          autoComplete="departureFlightTimeHour"
                          value={departureFlightTimeHour}
                          onChange={handleInput}
                          className="mt-1 rounded-l-md rounded-r-none inline-block"
                          errors={errors}
                        >
                          {hours.map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                              {hour === "12" ? "PM" : ""}
                            </option>
                          ))}
                        </Input>
                        <Input
                          type="select"
                          name="departureFlightTimeMinute"
                          id="departureFlightTimeMinute"
                          autoComplete="departureFlightTimeMinute"
                          value={departureFlightTimeMinute}
                          onChange={handleInput}
                          className="mt-1 rounded-r-md rounded-l-none inline-block"
                          errors={errors}
                        >
                          <option value="00">00</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </Input>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-1 mt-5 md:mt-0">
                      <label
                        htmlFor="departureDetail"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Dropoff Details
                      </label>
                      <Input
                        type="text"
                        name="departureDetail"
                        id="departureDetail"
                        autoComplete="departureDetail"
                        value={departureDetail}
                        onChange={handleInput}
                        className="mt-1"
                        errors={errors}
                      />
                    </div>
                  </div>
                </dd>
              </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Car Rego</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="carRego"
                  value={carRego}
                  onChange={handleInput}
                  errors={errors}
                />
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Additional Guests
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <AdditionalGuests
                  editMode={true}
                  additionalGuests={additionalGuests}
                  setProfile={setProfile}
                />
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Special Request
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Input
                  type="textarea"
                  rows={5}
                  value={guestRequest}
                  name="guestRequest"
                  onChange={handleInput}
                />
              </dd>
            </div>
            {!!isCreditCardDetails && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Credit Card
                  <div className="text-gray-500 text-xs mt-1 italic">
                    {creditCardMessage ||
                      "This card may be used to cover incidental items, security bond or damage incurred during your stay."}
                  </div>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm text-blue-700">
                          Your credit card information is safe with us. We are
                          in compliance with PCI Security Council standards.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 mt-5 md:mt-0">
                    <label
                      htmlFor="cardName"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Card Holder Name
                    </label>
                    <Input
                      type="text"
                      name="cardName"
                      id="cardName"
                      value={cardName}
                      onChange={handleInput}
                      autoComplete="cardName"
                      className="mt-1"
                      errors={errors}
                    />
                  </div>

                  <div className="col-span-12 mt-5">
                    <label
                      htmlFor="cardNumber"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Card number
                    </label>
                    <div className="mt-1 relative mb-5">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <CreditCardIcon
                          className={`h-5 w-5 ${
                            !cardNumberRaw
                              ? "text-gray-400"
                              : verifyCard(cardNumberRaw, [])
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                          aria-hidden="true"
                        />
                      </div>
                      <Input
                        type="text"
                        name="cardNumberRaw"
                        id="cardNumberRaw"
                        value={cardNumberRaw}
                        onChange={handleInput}
                        autoComplete="cardNumber"
                        className="mt-1 pl-10 rounded-md shadow-sm"
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className="col-span-12 md:col-span-3 gap-x-5 grid grid-cols-3 mt-5">
                    <div className="col-span-12 md:col-span-2 flex">
                      <div className="flex-grow">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Expire
                        </label>
                        <Input
                          type="select"
                          name="expiryMonth"
                          id="expiryMonth"
                          autoComplete="expiryMonth"
                          value={expiryMonth}
                          onChange={handleInput}
                          className="mt-1 rounded-l-md rounded-r-none"
                          errors={errors}
                        >
                          <option value=""></option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </Input>
                      </div>

                      <div className="flex-grow">
                        <label className="block text-sm font-medium text-white">
                          .
                        </label>
                        <Input
                          type="select"
                          name="expiryYear"
                          id="expiryYear"
                          autoComplete="expiryYear"
                          value={expiryYear}
                          onChange={handleInput}
                          className="mt-1 rounded-r-md rounded-l-none inline-block"
                          errors={errors}
                        >
                          <>
                            <option value="" />
                            {nextTenYearsArr.map((year) => (
                              <option value={getCCExpiryYear(year)}>
                                {year}
                              </option>
                            ))}
                          </>
                        </Input>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-1 mt-5 md:mt-0">
                      <label
                        htmlFor="cvc"
                        className="block text-sm font-medium text-gray-500"
                      >
                        CVC
                      </label>
                      <Input
                        type="text"
                        name="cvcNumberRaw"
                        id="cvcNumberRaw"
                        autoComplete="cvc"
                        value={cvcNumberRaw}
                        onChange={handleInput}
                        className="mt-1"
                        errors={errors}
                      />
                    </div>
                  </div>
                </dd>
              </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Terms & Conditions
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="line-clamp-10 rounded bg-gray-50 p-4 text-xs mb-4 whitespace-pre-line">
                  {tandc}
                </div>

                <Input
                  label="I agree to the terms and conditions."
                  type="checkbox"
                  name="agreeToTC"
                  id="agreeToTC"
                  checked={agreeToTC}
                  onChange={handleInput}
                  errors={errors}
                />
              </dd>
            </div>
            <div className="py-4 sm:py-5">
              <div className="text-center">
                <Button onClick={handleSubmit}>Complete Online Check In</Button>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

const AdditionalGuests = ({
  editMode,
  additionalGuests = [],
  setProfile,
}: {
  editMode: boolean;
  additionalGuests: any[];
  setProfile: any;
}) => {
  const handleAddGuest = () => {
    const newList = [
      ...additionalGuests,
      { firstName: "", lastName: "", mobile: "" },
    ];
    setProfile((val: any) => ({ ...val, additionalGuests: newList }));
  };

  const handleDeleteGuest = (index: number) => {
    const newList = [...additionalGuests];
    newList.splice(index, 1);
    setProfile((val: any) => ({ ...val, additionalGuests: newList }));
  };

  const handleInput = (e: any, index: number) => {
    const newList = [...additionalGuests];
    const newItem = newList?.[index] || {};
    newItem[e.target.name] = e.target.value;
    setProfile((val: any) => ({ ...val, additionalGuests: newList }));
  };

  return (
    <>
      {additionalGuests.map((guest, index) => {
        const { firstName, lastName, mobile } = guest;

        return (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 mb-2">
            <div className="mb-2 mb-md-0">
              <Input
                type="text"
                editMode={editMode}
                name="firstName"
                value={firstName}
                placeholder="First Name"
                onChange={(e: any) => handleInput(e, index)}
              />
            </div>
            <div className="mb-2 mb-md-0">
              <Input
                type="text"
                editMode={editMode}
                name="lastName"
                value={lastName}
                placeholder="Last Name"
                onChange={(e: any) => handleInput(e, index)}
              />
            </div>
            <div className="flex-between mb-2 mb-md-0">
              <Input
                type="text"
                editMode={editMode}
                name="mobile"
                value={mobile}
                placeholder="Mobile"
                onChange={(e: any) => handleInput(e, index)}
              />
              {editMode && (
                <XIcon
                  className="cursor-pointer ml-2 h-4 w-4 text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteGuest(index)}
                />
              )}
            </div>
          </div>
        );
      })}
      <div>
        <button
          onClick={handleAddGuest}
          type="button"
          className="inline-flex items-center py-2 px-6 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusSmIcon
            className="-ml-1 mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span className="text-sm font-medium text-gray-900">Add Guest</span>
        </button>
      </div>
    </>
  );
};
